import React from 'react';
import { Col, Divider, Row } from 'antd';
import Utils from '~commons/utils';

const Information = ({ dataInfo, accountList, datePickCont }) => {
  const renderCarAssigner = () => {
    const info = accountList?.find((item) => item?.code === dataInfo?.assignBy);
    if (info) {
      return (
        <span className='blue'>
          {info?.fullName}
          <span className='red'> ({info?.code})</span>
        </span>
      );
    } else {
      <span className='blue'>{dataInfo?.assignBy}</span>;
    }
  };

  return (
    <>
      <Divider
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        Thông tin hồ sơ
      </Divider>
      <Row gutter={16}>
        <Col className='gutter-row' span={6}>
          <div>
            Số Cont: <span className='blue'>{dataInfo?.contNumber}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={6}>
          <div>
            Nơi khởi hành: <span className='blue'>{dataInfo?.beginLocation}</span>
          </div>
        </Col>

        <Col className='gutter-row' span={6}>
          <div>Người điều xe: {renderCarAssigner()}</div>
        </Col>

        <Col className='gutter-row' span={6}>
          <div>
            {dataInfo?.orderType === 'Booking' ? 'Ngày giao rỗng: ' : 'Ngày giao hàng: '}
            <span className='blue'>{datePickCont && Utils.formatDate(datePickCont)}</span>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className='gutter-row' span={6}>
          <div>
            Số SubNo: <span className='blue'>{dataInfo?.subnoNumber}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={6}>
          <div>
            Nơi kết thúc: <span className='blue'>{dataInfo?.endLocation}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={6}>
          <div>
            Khách hàng: <span className='blue'>{dataInfo?.customerName}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={6}>
          <div>
            Loại ĐH: <span className='blue'>{dataInfo?.orderType}</span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export { Information };
