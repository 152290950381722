import React from 'react';
import { Col, Divider, Row } from 'antd';
import Utils from '~commons/utils';

const Information = ({ dataInfo, datePickCont }) => {
  return (
    <>
      <Divider
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        Thông tin hồ sơ
      </Divider>
      <Row gutter={16}>
        <Col className='gutter-row' span={4}>
          <div>
            Số Cont: <span className='blue'>{dataInfo?.contNumber}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={5}>
          <div>
            Số SubNo: <span className='blue'>{dataInfo?.subnoNumber}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={7}>
          <div>
            Khách hàng: <span className='blue'>{dataInfo?.customerName}</span>
          </div>
        </Col>
        <Col className='gutter-row' span={3}>
          <div>
            Loại ĐH: <span className='blue'>{dataInfo?.orderType}</span>
          </div>
        </Col>

        <Col className='gutter-row' span={5}>
          <div>
            {dataInfo?.orderType === 'Booking' ? 'Ngày giao rỗng: ' : 'Ngày giao hàng: '}
            <span className='blue'>{datePickCont && Utils.formatDate(datePickCont)}</span>
          </div>
        </Col>
      </Row>

      {/* <div>
        Ngày nhận data:
        <span className='blue'>
          {dataInfo?.assignDate && Utils.formatDate(dataInfo?.assignDate)}
        </span>
      </div> */}
    </>
  );
};

export { Information };
