import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Modal } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';
import { Header } from './header';
import { BillCont } from './bill-cont';
import { Information } from './information';
import { Expenses } from './expenses';
import { Tickets } from './ticket-list';
import { FormCreate } from './form-create';
import ApiServices from '~services';

const DEFAULT_QUERIES = {
  PageSize: 10,
  PageNumber: 1,
  DateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  DateTo: moment().endOf('date').format('YYYY-MM-DD'),
};

const CreateTicketBooking = () => {
  const [loading, setLoading] = useState(false);
  const [billContList, setBillContList] = useState([]);
  const [selectedBillCont, setSelectedBillCont] = useState(null);
  const [expensesList, setExpensesList] = useState([]);
  const [expensesIdSelected, setExpensesIdSelected] = useState([]);
  const [expensesSelected, setExpensesSelected] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_QUERIES);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dissolveDateDefault, setDissolveDateDefault] = useState(moment());

  const [datePickCont, setDatePickCont] = useState(null);

  const getBookingCont = async (query = DEFAULT_QUERIES) => {
    setLoading(true);
    const newQuery = { ...query };

    return ApiServices.getBookingCont(newQuery)
      .then((res) => {
        if (res?.data?.data) {
          const mapBillData = res?.data?.data?.map((item) => {
            item.key = item?.contId;
            return item;
          });
          setBillContList(mapBillData || []);
          setTotalRecords(res?.data?.totalRecords);
        }
      })
      .catch((err) => {
        console.error('Get Bill Cont Error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBookingByContId = async (contId) => {
    setLoading(true);
    return ApiServices.getBookingByContId(contId)
      .then((res) => {
        if (res?.data?.data) {
          const mapExpenses = res?.data?.data?.expenses?.map((item) => {
            item.key = item.id;
            item.isExpenses = item?.objTicketExpenses ? true : false;
            item.billRequired = item?.billRequired ? true : false;
            item.price = item?.objTicketExpenses ? item?.objTicketExpenses?.price : '';
            item.vat = item?.objTicketExpenses ? item?.objTicketExpenses?.vat : '';
            item.total = item?.objTicketExpenses
              ? item?.objTicketExpenses?.total
              : item?.fee
              ? item?.fee
              : '';
            item.billNumber = item?.objTicketExpenses ? item?.objTicketExpenses?.billNumber : '';
            item.billDate = item?.objTicketExpenses ? item?.objTicketExpenses?.billDate : null;
            item.supplierName = item?.objTicketExpenses
              ? item?.objTicketExpenses?.supplierName
              : item?.supplier
              ? item?.supplier
              : '';

            return item;
          });
          setDatePickCont(res?.data?.data?.datePickCont);
          setExpensesList(mapExpenses || []);
          setTicketList(res?.data?.data?.tickets || []);
        }
      })
      .catch((err) => {
        console.error('Get Bill by ContId: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onColumnSearch = (event, select = '') => {
    setFilter((prev) => {
      const Columns = { ...prev.Columns };
      const value = select ? event : event.target.value;
      const name = select ? select : event.target.name;
      if (!value) {
        delete Columns[name];
        return { ...prev, Columns, PageNumber: 1 };
      }
      Columns[name] = value;
      return { ...prev, Columns, PageNumber: 1 };
    });
  };

  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      getBookingCont(newFilter);
    }, 500),
    []
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  useEffect(() => {
    if (selectedBillCont?.contId) {
      getBookingByContId(selectedBillCont?.contId);
    }
  }, [selectedBillCont?.contId]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const reload = (contId) => {
    getBookingByContId(contId);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setIsModalOpen(false);
  };

  const handleSelectedBillCont = (value) => {
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setSelectedBillCont(value);
  };

  const clearDataWhenSearch = () => {
    setSelectedBillCont([]);
    setExpensesList([]);
    setExpensesIdSelected([]);
    setExpensesSelected([]);
    setDatePickCont(null);
  };

  const pagination = {
    total: totalRecords || 0,
    showSizeChanger: false,
    pageSize: filter.PageSize,
    current: filter.PageNumber,
    onChange: (PageNumber) =>
      setFilter((prev) => ({
        ...prev,
        PageNumber,
      })),
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Header
          expensesIdSelected={expensesIdSelected}
          showModal={showModal}
          filter={filter}
          setFilter={setFilter}
          dissolveDateDefault={dissolveDateDefault}
          setDissolveDateDefault={setDissolveDateDefault}
          clearDataWhenSearch={clearDataWhenSearch}
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
          height: '490px',
        }}
      >
        <Row>
          <Col flex='350px'>
            <div style={{ width: '350px' }}>
              <BillCont
                dataBillCont={billContList}
                setSelectedBillCont={handleSelectedBillCont}
                onColumnSearch={onColumnSearch}
                pagination={pagination}
              />
            </div>
            <div style={{ width: '350px' }}>
              <Information dataInfo={selectedBillCont} datePickCont={datePickCont} />
            </div>
          </Col>
          <Col flex='auto'>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
              }}
            >
              <Expenses
                dataExpenses={expensesList}
                expensesIdSelected={expensesIdSelected}
                setExpensesIdSelected={setExpensesIdSelected}
                setExpensesSelected={setExpensesSelected}
                loadingData={loading}
              />
            </div>
            <div
              style={{
                maxWidth: 'calc(100vw - 600px)',
                marginLeft: '10px',
                marginTop: '20px',
              }}
            >
              <Tickets ticketList={ticketList} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title='Thêm phiếu giải trừ Booking'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        style={{
          top: 20,
        }}
        width='80%'
      >
        <FormCreate
          dataInfo={selectedBillCont}
          expensesSelected={expensesSelected}
          reload={reload}
          dissolveDateDefault={dissolveDateDefault}
        />
      </Modal>
    </div>
  );
};

export { CreateTicketBooking };
