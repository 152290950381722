import React, { useState, useEffect, useCallback } from 'react';
import { Space, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { Wrapper } from '~commons/styles';
import { TableLayout } from '~components';
import Utils from '~commons/utils';
import ApiServices from '~services';
import { TableTitle } from '~commons/styles';
import { DATE_FORMAT } from '~commons/constants';

const CustomerExpireContactList = ({ tabKey }) => {
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  const [contractDate, setContractDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);

  const getContractExpireList = async () => {
    setLoading(true);
    ApiServices.getContractExpireList()
      .then((res) => {
        if (res?.data?.data) {
          const mapData = res?.data?.data?.map((item, index) => {
            return {
              ...item,
              keyIndex: index + 1,
            };
          });
          setOriginData(mapData || []);
          setData(mapData || []);
        }
      })
      .catch((err) => {
        console.error('Get Orders Waiting receive from ONP error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getContractExpireList();
  }, [tabKey]);

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];
    dataInit?.map((item) => {
      let checkFilter = true;
      if (query?.contractNumber) {
        if (
          !Utils.convertValSearch(item?.contractNumber)?.includes(
            Utils.convertValSearch(query?.contractNumber)
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.customerName) {
        if (
          !Utils.convertValSearch(item?.customerName)?.includes(
            Utils.convertValSearch(query?.customerName)
          )
        ) {
          checkFilter = false;
        }
      }

      if (query?.contractDate) {
        if (moment(item?.contractDate).format('YYYY-MM-DD') !== query?.contractDate) {
          checkFilter = false;
        }
      }
      if (query?.expiredDate) {
        if (moment(item?.expiredDate).format('YYYY-MM-DD') !== query?.expiredDate) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });
    setData(dataFilter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    []
  );
  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filter, originData);
    }
  }, [filter]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeContractDate = (date) => {
    setContractDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, contractDate: dateSearch };
    });
  };

  const handleChangeExpireDate = (date) => {
    setExpiredDate(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilter((prev) => {
      return { ...prev, renewExpiredDate: dateSearch };
    });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'keyIndex',
      width: 50,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Số Hợp Đồng
          <Input name='contractNumber' onChange={onColumnSearch} placeholder='Search' allowClear />
        </TableTitle>
      ),
      dataIndex: 'contractNumber',
      width: 200,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Tên khách hàng
          <Input name='customerName' onChange={onColumnSearch} placeholder='Search' allowClear />
        </TableTitle>
      ),
      dataIndex: 'customerName',
      width: 200,
      align: 'center',
    },
    {
      title: (
        <TableTitle>
          Ngày Hợp Đồng
          <DatePicker
            value={contractDate}
            onChange={handleChangeContractDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'contractDate',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: (
        <TableTitle>
          Ngày Hết Hạn
          <DatePicker
            value={expiredDate}
            onChange={handleChangeExpireDate}
            placeholder='Search'
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            allowClear={true}
          />
        </TableTitle>
      ),
      dataIndex: 'expiredDate',
      width: 130,
      align: 'center',
      render: (value) => Utils.formatDate(value),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 180,
      align: 'center',
    },
  ];

  const headerContent = (
    <Space>
      <h3>DANH SÁCH KHÁCH HÀNG HẾT HẠN HỢP ĐỒNG</h3>
    </Space>
  );

  return (
    <Wrapper>
      <TableLayout
        columns={columns}
        data={data}
        rowKey='id'
        bordered
        loading={loading}
        headerContent={headerContent}
        headerLength='220px'
        pagination={false}
        isDisplayCheckbox={false}
      />
    </Wrapper>
  );
};

export { CustomerExpireContactList };
